import { NgModule } from '@angular/core';

import { PanCommonModule, PanFormModule } from '@pan/lib--web-ui-components/core';
import { AccordionComponent } from './accordion';
import { AccordionGroupComponent } from './accordion-group';

@NgModule({
  imports: [ PanCommonModule, PanFormModule ],
  exports: [ AccordionComponent, AccordionGroupComponent ],
  declarations: [ AccordionComponent, AccordionGroupComponent ],
})
export class PanAccordionModule { }
