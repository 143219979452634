import {
  Component,
  forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  template: ``,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => BaseFormComponent),
    multi: true
  }],
})

export class BaseFormComponent implements ControlValueAccessor {

  protected _innerValue = null;
  protected _isDisabled = false;
  protected _onChange (fn: any) {}
  protected _onTouched () {}

  /**
   * Get the inner component value
   */
  get innerValue () {
    return this._innerValue;
  }

  /**
   * Set the inner component value and fire the change callback
   */
  set innerValue (value) {
    if (value !== this._innerValue) {
      this._innerValue = value;
      this._onChange(value);
    }
  }

  /**
   * Check if the component is disabled
   */
  get isDisabled (): boolean {
    return this._isDisabled;
  }

  /**
   * Blur event of the input
   */
  onBlur (): void {
    this._onTouched();
  }

  /**
   * ControlValueAccessor: write the incomming model change
   * @param value
   */
  writeValue (value: any): void {
    if (value !== this.innerValue) {
        this.innerValue = value;
    }
  }

  /**
   * ControlValueAccessor: register the on change callback
   * @param value
   */
  registerOnChange (fn: any): void {
    this._onChange = fn;
  }

  /**
   * ControlValueAccessor: register the onBlur callback
   * @param value
   */
  registerOnTouched (fn: any): void {
    this._onTouched = fn;
  }

  /**
   * ControlValueAccessor: set the isDisabled state
   * @param isDisabled
   */
  setDisabledState (isDisabled: boolean) {
    this._isDisabled = isDisabled;
  }
}
