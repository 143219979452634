import { NgModule } from '@angular/core';

import { PanCommonModule } from '@pan/lib--web-ui-components/core';
import { RadioOptionComponent } from './radio-option';

@NgModule({
  imports: [ PanCommonModule ],
  exports: [ RadioOptionComponent ],
  declarations: [ RadioOptionComponent ],
})
export class PanRadioOptionModule { }
