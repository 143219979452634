import { NgModule } from '@angular/core';

import { PanCommonModule, PanFormModule } from '@pan/lib--web-ui-components/core';
import { InputComponent } from './input';

@NgModule({
  imports: [ PanCommonModule, PanFormModule ],
  exports: [ InputComponent ],
  declarations: [ InputComponent ],
})
export class PanInputModule { }
