import {
  Component,
  forwardRef,
  Input
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormComponent } from './base-form.component';

@Component({
  template: ``,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormAttrComponent),
    multi: true
  }],
})

export class FormAttrComponent extends BaseFormComponent {


  @Input()
  set disabled (value: boolean) {
    this._isDisabled = value;
  }

  @Input()
  get minlength(): number { return this._minlength; }
  set minlength (value: number) { this._minlength = value; }
  protected _minlength: number;

  @Input()
  get maxlength(): number { return this._maxlength; }
  set maxlength (value: number) { this._maxlength = value; }
  protected _maxlength: number;

  @Input()
  get tabIndex(): number { return this._tabIndex; }
  set tabIndex (value: number) { this._tabIndex = value; }
  protected _tabIndex = 0;

  @Input()
  get title(): string { return this._title; }
  set title (value: string) { this._title = value; }
  protected _title: string;

}
