import { Component, Input, forwardRef, ChangeDetectionStrategy } from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormComponent, FormAttrComponent } from '@pan/lib--web-ui-components/core';


@Component({
  selector: 'mh-text-field',
  template: `<div class="app-input">
  <input mh-input
  class="app-input__input"
  [type]="type"
  [name]="name"
  [(ngModel)]="innerValue"
  [class.app-input__input--filled]="innerValue"
  [disabled]="isDisabled">

  <label class="app-input__label">{{placeholder}}</label>
</div>
`,
  styles: [`:host{display:block;position:relative;width:100%}:host:after{position:absolute;top:calc(100% + 3px);left:0;color:#616161;font-size:.875rem;content:attr(assistive)}:host :disabled :host:after{color:#9e9e9e}:host(.ng-invalid.ng-dirty):after{position:absolute;top:calc(100% + 3px);left:0;color:#e32000;font-size:.875rem;content:attr(error)}:host(.ng-invalid.ng-dirty) label{color:#e32000!important}.app-input{position:relative;width:100%;margin:5px 0 0}.app-input__label{position:absolute;top:calc(48px / 2);left:16px;-webkit-transform:translateY(-50%);transform:translateY(-50%);transition:.2s;color:#757575;font-size:1rem;font-weight:400;z-index:4;pointer-events:none}.app-input__label:before{position:absolute;top:calc(50% - (48px/2));left:0;width:100%;height:6px;-webkit-transform:translateY(-50%);transform:translateY(-50%);transition:transform .2s,top .2s,-webkit-transform .2s;background-color:#fff;content:'';z-index:-1}.app-input__input:focus~.app-input__label{top:0;left:14px;padding:0 3px;color:#414141;font-size:.875rem;font-weight:600}.app-input__input:focus~.app-input__label:before{top:50%;-webkit-transform:scaleX(1) translateY(-50%);transform:scaleX(1) translateY(-50%)}.app-input__input--filled~.app-input__label{top:0;left:14px;padding:0 3px;color:#616161;font-size:.875rem;font-weight:600}.app-input__input--filled~.app-input__label:before{top:50%;-webkit-transform:scaleX(1) translateY(-50%);transform:scaleX(1) translateY(-50%)}.app-input__input:disabled~.app-input__label{color:#9e9e9e}`],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MhTextFieldComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MhTextFieldComponent extends BaseFormComponent {

  @Input()
  name: string;

  @Input()
  type: string;

  @Input()
  placeholder: string;

  @Input()
  error: string;

  @Input()
  set disabled (value: boolean) {
    this._isDisabled = value;
  }
}
