import {
  trigger,
  animate,
  transition,
  style,
  state,
  AnimationTriggerMetadata
} from '@angular/animations';

export const accordionAnimation: AnimationTriggerMetadata =
  trigger('accordion', [
    state('0', style({
      height: '0px',
      opacity: 0,
      marginBottom: 0
    })),
    state('1', style({
      height: '*',
      opacity: 1,
      marginBottom: '20px'
    })),
    transition('void => *', animate(0)),
    transition('* => *', animate('{{ duration }} ease-out'), { params: { duration: '450ms' } })
  ]);
