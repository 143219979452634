import {
  Component,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import { accordionAnimation } from './accordion.animation';

@Component({
  selector: 'pan-accordion',
  template: `<div class="accordion">
  <input class="accordion__radio"
    type="checkbox"
    [id]="identifier"
    [name]="identifier"
    (change)="onChange()"
    [(ngModel)]="isOpened"
    tabindex="0"
  />
  <label class="accordion__header"
    [for]="identifier"
  >
    <ng-content select="[accordion-header]"></ng-content>
  </label>

  <div class="accordion__content" [@accordion]="{ value: isOpened, params: { duration: duration } }">
    <ng-content select="[accordion-content]"></ng-content>
  </div>
</div>
`,
  styles: [`.accordion__radio{position:absolute;width:1px;height:1px;margin:-1px;padding:0;border:0;overflow:hidden;clip:rect(0 0 0 0)}:host{display:block}:host:last-of-type{margin-bottom:32px;border-bottom:2px solid #bdbdbd}.accordion{width:100%;border-top:2px solid #bdbdbd}.accordion__content,.accordion__header{width:inherit}.accordion__radio:focus~.accordion__header{box-shadow:0 1px 2px #212121}.accordion__radio:checked~.accordion__header:after{-webkit-transform:rotate(-225deg);transform:rotate(-225deg)}.accordion__header{display:flex;align-items:center;justify-content:space-between;height:56px;padding:5px 15px 5px 5px;color:#212121;font-weight:500;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.accordion__header:after{width:12px;height:12px;display:block;-webkit-transform:translateY(3px) rotate(-45deg);transform:translateY(3px) rotate(-45deg);transition:transform .45s cubic-bezier(0,1.05,0,1);transition:transform .45s cubic-bezier(0,1.05,0,1),-webkit-transform .45s cubic-bezier(0,1.05,0,1);border-width:3px 3px 0 0;border-style:solid;content:''}.accordion__content{padding:0 5px 5px;overflow:hidden}`],
  animations: [ accordionAnimation ]
})
export class AccordionComponent {

  @Input()
  set opened (value) {
    this.isOpened = value;
  }

  @Input()
  name: string;

  @Output()
  updatedValue = new EventEmitter<boolean>();

  public isOpened = false;
  public duration = '450ms';
  private uniqueStr: string;

  constructor () {
    this.uniqueStr = btoa(Math.random() + '').substring(0, 12);
  }

  /**
   * Return an unique identifier for the checkbox control
   */
  get identifier (): string {
    return this.name || this.uniqueStr;
  }

  /**
   * Emit the current isOpened value
   */
  onChange () {
    this.updatedValue.emit(this.isOpened);
  }
}
