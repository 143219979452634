import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BaseFormComponent } from './base-form.component';
import { FormAttrComponent } from './form-attr.component';
import { MhInputModule } from './components/index';

@NgModule({
  imports: [ CommonModule, FormsModule, MhInputModule ],
  exports: [ CommonModule, FormsModule, BaseFormComponent, MhInputModule, FormAttrComponent ],
  declarations: [ BaseFormComponent, FormAttrComponent ],
})
export class PanFormModule { }
