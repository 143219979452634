import { NgModule } from '@angular/core';

import { PanCommonModule, PanFormModule } from '@pan/lib--web-ui-components/core';

import { InputPasswordStateIconComponent } from './icons/state-icon/state-icon';
import { InputPasswordComponent } from './input-password';

@NgModule({
  imports: [ PanCommonModule, PanFormModule ],
  exports: [ InputPasswordComponent, InputPasswordStateIconComponent ],
  declarations: [ InputPasswordComponent, InputPasswordStateIconComponent ]
})
export class PanInputPasswordModule {}
