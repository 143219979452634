import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormComponent } from '../../base-form.component';

@Component({
  selector: 'mh-input, [mh-input], input[mh-input]',
  // templateUrl: './input.html',
  template: '',
  styles: [`:host{width:100%;height:48px;padding:0 16px;transition:.2s;border:1px solid #757575;border-radius:4px;outline:0;background-color:#fff;color:#212121;font-size:1rem;font-weight:400}:host:hover{border-color:#212121}:host:focus{border-width:2px;border-color:#414141;font-weight:600}:host:disabled{border-color:#9e9e9e;color:#9e9e9e}:host(.ng-invalid.ng-dirty){border-width:2px;border-color:#e32000}`],
  // providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MhInputComponent), multi: true }]
})
export class MhInputComponent {

  @Input()
  name: string;

  @Input()
  type: string;
}
