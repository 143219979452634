import {
  trigger,
  animate,
  style,
  query,
  transition,
  AnimationTriggerMetadata
} from '@angular/animations';

export const slideAnimation: AnimationTriggerMetadata = trigger('slideAnimation', [
  transition('* <=> *', [
      query(':leave', [
          style({
              position: 'fixed',
              width: '100%',
              transform: 'translateX(0)'
          })
      ], { optional: true }),

      query(':enter', [
          style({
              position: 'fixed',
              width: '100%',
              transform: 'translateX(-100%)'
          }),
      ], { optional: true }),

      query(':leave', [
          animate('.4s ease',
              style({
                  transform: 'translateX(100%)'
              })
          )
      ], { optional: true }),

      query(':enter', [
          animate('.4s ease',
              style({
                  transform: 'translateX(0)'
              })
          )
      ], { optional: true })
  ])
]);
