import { NgModule } from '@angular/core';

import { PanCommonModule } from '@pan/lib--web-ui-components/core';
import { DownloadButtonComponent } from './download-button';

@NgModule({
  imports: [ PanCommonModule ],
  exports: [ DownloadButtonComponent ],
  declarations: [ DownloadButtonComponent ],
})
export class PanDownloadButtonModule { }
