import { NgModule } from '@angular/core';

import { PanCommonModule, PanFormModule } from '@pan/lib--web-ui-components/core';

import { MhTextFieldComponent } from './text-field';

@NgModule({
  imports: [ PanCommonModule, PanFormModule ],
  exports: [ MhTextFieldComponent ],
  declarations: [ MhTextFieldComponent ],
})
export class MhTextFieldModule { }
