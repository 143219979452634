import { Component, Input } from '@angular/core';
import { grow } from './values-chart.animation';

export interface ValuesChartItem {
  value: number;
  netValue?: number;
  percent?: number;
  background?: string;
  label?: string;
}

@Component({
  selector: 'pan-values-chart',
  template: `<div class="values-chart">
  <div *ngFor="let item of items" class="values-chart__row">
    <div class="values-chart__column">
      <div class="values-chart__bar"
        [title]="item.label"
        [@grow]="{value: growState, params: { percent: item.percent }}"
        [ngStyle]="{backgroundColor: item.background || '' }"
      >
        <span>{{ item.label }}</span>
      </div>
    </div>
    <span class="values-chart__value">
      {{ item.value | currency:'R$':'code' }}
    </span>
  </div>
</div>
`,
  styles: [`:host{width:100%}.values-chart{display:flex;flex-direction:column;align-items:center;width:inherit;margin-top:24px}.values-chart__row{display:flex;align-items:center;justify-content:space-between;width:100%;margin-bottom:8px}.values-chart__row:nth-child(even) .values-chart__bar{background-color:#8ac}.values-chart__row:nth-child(odd) .values-chart__bar{background-color:#f90}.values-chart__row:last-child .values-chart__bar{background-color:#d0d5da;color:#414141}.values-chart__row:last-child .values-chart__value{color:#414141;font-size:.875rem}.values-chart__column{flex-basis:100%;width:100%}.values-chart__bar{max-width:0;padding:8px;border-radius:4px;color:#fff;font-size:.75rem;font-weight:600;text-align:left;will-change:max-width}.values-chart__bar span{display:block;min-width:135px;font-size:.75rem;text-overflow:ellipsis;white-space:nowrap}.values-chart__value{margin-left:10px;color:#212121;font-size:.75rem;font-weight:600;white-space:nowrap}@media screen and (min-width:768px){.values-chart__value{margin-left:15px;font-size:1rem}}`],
  animations: [ grow ]
})
export class ValuesChartComponent {

  public growState = null;
  public highestValue: number;
  private _chartItems: ValuesChartItem[] = [];
  private _baseValue: number;

  @Input()
  set baseValue (value) {
    this._baseValue = value;
  }

  get baseValue () {
    return this._baseValue;
  }

  @Input()
  calcPercent = true;

  @Input()
  set items (values: ValuesChartItem[]) {
    values = values || [];
    values = this._sortValues(values);

    if (!this.baseValue) {
      this._determineHighestValue(values);
    }

    if (this.calcPercent) {
      this._chartItems = values.map(i => this._mapChartValue(i));
    } else {
      this._chartItems = values;
    }

    this._toggleGrowState();
  }

  get items () {
    return this._chartItems;
  }

  private _sortValues (values: ValuesChartItem[] = []): ValuesChartItem[] {
    return values.sort((a, b) => {
      return ( (b.netValue || b.value) - (a.netValue || a.value) );
    });
  }

  private _mapChartValue (item: ValuesChartItem): ValuesChartItem {
    item.percent = this._calcPercent(item.netValue || item.value);
    return item;
  }

  private _toggleGrowState () {
    this.growState = (this.growState === 'on')
      ? 'off'
      : 'on';
  }

  private _calcPercent (current: number): number {
    return (current * 100) / (this.baseValue || this.highestValue);
  }

  private _determineHighestValue (values) {
    const [ firstItem ] = values;

    if (firstItem) {
      this.highestValue = (firstItem.netValue || firstItem.value);
    }
  }
}
