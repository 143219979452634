import { NgModule } from '@angular/core';

import { PanCommonModule, PanFormModule } from '@pan/lib--web-ui-components/core';
import { InputRangeComponent } from './input-range';

@NgModule({
  imports: [ PanCommonModule, PanFormModule ],
  exports: [ InputRangeComponent ],
  declarations: [ InputRangeComponent ],
})
export class PanInputRangeModule { }
