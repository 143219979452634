import {
  AfterContentInit,
  Component,
  ContentChildren,
  QueryList,
  forwardRef,
  HostBinding,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormComponent } from '@pan/lib--web-ui-components/core';

import { RadioComponent } from './radio';

@Component({
  selector: 'pan-radio-group',
  template: `<ng-content></ng-content>`,
  styles: [`
    :host {
      display: inline-block;
    }
  `],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RadioGroupComponent),
    multi: true
  }],
})
export class RadioGroupComponent extends BaseFormComponent implements AfterContentInit {
  private oldValue;

  @Input() group: string;
  @Input() styleType: string;
  @Input() isToggleable = false;

  @Output() $onChange: EventEmitter<any> = new EventEmitter();

  @ContentChildren(forwardRef(() => RadioComponent)) groups: QueryList<RadioComponent>;

  ngAfterContentInit () {
    this.groups.changes.subscribe(() => {
      setTimeout(() => {
        this.configChildren();
      });
    });

    this.configChildren();
  }

  configChildren() {
    this.groups.toArray().forEach((contentChild, i) => {
      if (this.styleType) {
        contentChild.layout = this.styleType;
      }
      contentChild.group = this.group;
      contentChild.name = `${this.group}-${i}`;

      contentChild.$onChange.subscribe(data => {
        if (!data.checked && this.isToggleable) {
          data.checked = false;
          contentChild.isSelected = false;
          this.$onChange.emit(null);
          return;
        }

        this.groups.toArray().forEach(child => {
          const element = child.element.nativeElement;
          element.checked = false;
          child.isSelected = false;
        });

        data.checked = true;
        contentChild.isSelected = true;

        if (this.oldValue !== contentChild.value || this.isToggleable) {
          this.innerValue = contentChild.value;
          this.$onChange.emit(contentChild.value);
          this.oldValue = contentChild.value;
        }
      });
    });
  }
}
