import {
  ContentChildren,
  Component,
  QueryList,
  Input,
  forwardRef,
  AfterContentInit
} from '@angular/core';

import { AccordionComponent } from './accordion';

@Component({
  selector: 'pan-accordion-group',
  template: `<div class="accordion-group">
  <ng-content></ng-content>
</div>
`,
  styles: [`:host{display:block;margin:16px 0}`]
})
export class AccordionGroupComponent implements AfterContentInit {

  @Input()
  singleOpened = false;

  @Input()
  animation = true;

  @ContentChildren(forwardRef(() => AccordionComponent))
  groups: QueryList<AccordionComponent>;

  ngAfterContentInit () {
    this.observeAccordions();
  }

  /**
  * Watch for changes in the children Accordions
  * and keep only one opened, closing the others
  */
  observeAccordions () {
    this.groups.forEach((contentChild, i) => {

      if (!this.animation) {
        contentChild.duration = '0ms';
      }

      const updatedValue$ = contentChild.updatedValue.asObservable();
      updatedValue$.subscribe(newValue => {
        this.groups.toArray().forEach((accordion, j) => {
          if (this.singleOpened && i !== j) {
            accordion.opened = false;
          }
        });
      });
    });
  }
}
