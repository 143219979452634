import { NgModule } from '@angular/core';

import { PanCommonModule, PanFormModule } from '@pan/lib--web-ui-components/core';
import { FileUploadComponent } from './file-upload/file-upload';
import { FilePlaceholderComponent } from './file-placeholder/file-placeholder';



@NgModule({
  imports: [ PanCommonModule, PanFormModule ],
  exports: [ FilePlaceholderComponent, FileUploadComponent, PanCommonModule ],
  declarations: [ FilePlaceholderComponent, FileUploadComponent ],
})
export class PanFileUploadModule { }
