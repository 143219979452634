import { NgModule } from '@angular/core';

import { PanCommonModule } from '@pan/lib--web-ui-components/core';
import { StepsComponent } from './steps';

@NgModule({
  imports: [ PanCommonModule ],
  exports: [ StepsComponent ],
  declarations: [ StepsComponent ],
})
export class PanStepsModule { }
