import {
  trigger,
  transition,
  animate,
  style,
  state,
  AnimationTriggerMetadata
} from '@angular/animations';

export const grow: AnimationTriggerMetadata[] = [
  trigger('grow', [
    state('off', style({
      maxWidth: '{{ percent }}%'
    }), { params: { percent: '0%'} }),

    state('on', style({
      maxWidth: '{{ percent }}%'
    }), { params: { percent: '0%'} }),

    transition('* => *', [
      animate('.2s ease-in-out', style({
        maxWidth: '0%'
      })),
      animate('.8s ease-in-out', style({
        maxWidth: '{{ percent }}%'
      }))
    ], { params: { percent: '0%' } })
  ])
];
