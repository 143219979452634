import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MhInputComponent } from './input';

@NgModule({
  declarations: [
    MhInputComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    MhInputComponent
  ],
  providers: [ ],
})
export class MhInputModule { }
