import { NgModule } from '@angular/core';

import { PanCommonModule } from '@pan/lib--web-ui-components/core';
import { ButtonComponent } from './button';

@NgModule({
  imports: [ PanCommonModule ],
  exports: [ ButtonComponent ],
  declarations: [ ButtonComponent ],
})
export class PanButtonModule { }
