import { Component, Input, forwardRef, HostBinding } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseFormComponent } from '@pan/lib--web-ui-components/core';

@Component({
  selector: 'pan-input-password',
  template: `<div class="container-password password">
  <div class="password__box">
    <input class="password__input"
      [type]="typeInput"
      [placeholder]="placeholder"
      [maxlength]="maxLength"
      [(ngModel)]="innerValue"
    >
    <button class="password__toggle" (click)="changeType()">
      <pan-input-password-state-icon [isPasswordModeOn]="showText"></pan-input-password-state-icon>
    </button>
  </div>
  <p *ngIf="!error; else showError" class="password__label">{{ label }}</p>
  <ng-template #showError>
    <p class="password__error">{{ error }}</p>
  </ng-template>
</div>
`,
  styles: [`:host{display:block;max-width:300px;margin:20px auto}:host(.ng-invalid.ng-dirty) .password__input{border-color:#e32000}.password__box{display:flex;position:relative}.password__input{flex:1;padding:17px 30px 17px 8px;border:1px solid #757575;border-radius:4px;background-color:#fff;font-size:1rem}.password__input::-webkit-input-placeholder{color:#414141;font-size:1rem}.password__input:-ms-input-placeholder{color:#414141;font-size:1rem}.password__input::-ms-input-placeholder{color:#414141;font-size:1rem}.password__input::placeholder{color:#414141;font-size:1rem}.password__input:focus{border-color:#212121;-webkit-animation:.3s ease-out scale;animation:.3s ease-out scale}.password__toggle{display:flex;position:absolute;top:0;right:10px;bottom:0;margin:auto;background:0 0}.password__toggle svg{stroke:#9e9e9e}.password__error,.password__label{margin-top:8px;color:#616161;font-size:.75rem;line-height:1.7}.password__label--disable{display:none}.password__error{color:#e32000;font-weight:700}@-webkit-keyframes scale{50%{-webkit-transform:scale(1.03);transform:scale(1.03)}100%{-webkit-transform:scale(1);transform:scale(1)}}@keyframes scale{50%{-webkit-transform:scale(1.03);transform:scale(1.03)}100%{-webkit-transform:scale(1);transform:scale(1)}}`],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputPasswordComponent),
    multi: true
  }],
})
export class InputPasswordComponent extends BaseFormComponent {
  public showText = false;

  @Input()
  error: string;

  @Input()
  label: string;

  @Input()
  placeholder = '';

  @Input()
  maxLength = 8;

  changeType(): void {
    this.showText = !this.showText;
  }

  get typeInput(): string {
    return this.showText ? 'text' : 'password';
  }

}
