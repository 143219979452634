import { NgModule } from '@angular/core';

import { PanCommonModule, PanFormModule } from '@pan/lib--web-ui-components/core';
import { RadioComponent } from './radio';
import { RadioGroupComponent } from './radio-group';

@NgModule({
  imports: [ PanCommonModule, PanFormModule ],
  exports: [ RadioComponent, RadioGroupComponent ],
  declarations: [ RadioComponent, RadioGroupComponent ],
})
export class PanRadioModule { }
