import { NgModule } from '@angular/core';

import { PanCommonModule } from '@pan/lib--web-ui-components/core';
import { ValuesChartComponent } from './values-chart';

@NgModule({
  imports: [ PanCommonModule ],
  exports: [ ValuesChartComponent ],
  declarations: [ ValuesChartComponent ],
})
export class PanValuesChartModule { }
